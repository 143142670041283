import React from "react";

function EndText() {
  return (
    <div>
      <a
        href="https://github.com/rakshixh"
        target="_blank"
        rel="noreferrer"
        className="endText"
        style={{ animation: "fadeInEffect 2s ease-in-out forwards" }}
      >
        <p className="endText">rakshixh</p>
      </a>
    </div>
  );
}

export default EndText;
